<template>
  <a-modal :width="1000" :visible="visible" title="单据下载" :body-style="{ paddingTop: 0 }" @update:visible="updateVisible">
    <template #footer>
      <a-space>
        <a-button @click="handleCancle"> 取消 </a-button>
        <a-button type="primary" @click="save">保存</a-button>
        <a-button @click="savePdf"> 生成pdf </a-button>
      </a-space>
    </template>
    <div class="form-title">厦门边检总站公物仓资产调剂申请单</div>
    <div class="table">
      <table border="1" borderColor="#f0f0f0" width="100%" align="center" cellspacing="0" cellpadding="10"
        style="table-layout: fixed">
        <tr>
          <td colspan="3">
            <div class="table-flex">
              <div class="suggest">总站后勤保障处意见：</div>
              <div></div>
            </div>
          </td>
          <td colspan="6">
            <div class="table-sub">
              <a-form-item label="经办人" :label-col="{ span: 6 }" :wrapper-col="{ span: 17 }">
                <a-input placeholder="自动获取" v-model:value="tableForm.operator" disabled></a-input>
              </a-form-item>
              <a-form-item label="处领导" :label-col="{ span: 6 }" :wrapper-col="{ span: 17 }">
                <a-input placeholder="请输入处领导" v-model:value="tableForm.leader" disabled></a-input>
              </a-form-item>
            </div>
          </td>
          <td colspan="3">
            <div class="table-flex">
              <div class="suggest">分管后勤保障处意见：</div>
              <div></div>
            </div>
          </td>
        </tr>
        <tr class="tr_hover">
          <td colspan="6">
            <div class="table-flex">
              <div>
                <div>申请单位：</div>
                <div>(签章)</div>
              </div>
              <div></div>
            </div>
          </td>
          <td colspan="6">
            <div class="table-flex">
              <div>
                <div>公物仓管理单位：</div>
                <div>(签章)</div>
              </div>
              <div></div>
            </div>
          </td>
        </tr>
        <tr class="tr_hover">
          <td colspan="6">
            <div>
              <a-form-item label="经办人" :label-col="{ span: 5 }">
                <a-input placeholder="请输入经办人" v-model:value="tableForm.approveOperator" allowClear></a-input>
              </a-form-item>
            </div>
          </td>
          <td colspan="6">
            <div>
              <a-form-item label="经办人" :label-col="{ span: 5 }">
                <a-input placeholder="请输入经办人" v-model:value="tableForm.storeOperator" allowClear></a-input>
              </a-form-item>
            </div>
          </td>
        </tr>
        <tr class="tr_hover">
          <td colspan="6">
            <div>
              <a-form-item label="资产管理员" :label-col="{ span: 5 }">
                <a-input placeholder="请输入资产管理员" v-model:value="tableForm.approveAssetManager" allowClear></a-input>
              </a-form-item>
            </div>
          </td>
          <td colspan="6">
            <div>
              <a-form-item label="资产管理员" :label-col="{ span: 5 }">
                <a-input placeholder="请输入资产管理员" v-model:value="tableForm.storeAssetManager" allowClear></a-input>
              </a-form-item>
            </div>
          </td>
        </tr>
        <tr class="tr_hover">
          <td colspan="6">
            <div>
              <a-form-item label="财务人员" :label-col="{ span: 5 }">
                <a-input placeholder="请输入财务人员" v-model:value="tableForm.approveFinancialStaff" allowClear></a-input>
              </a-form-item>
            </div>
          </td>
          <td colspan="6">
            <div>
              <a-form-item label="财务人员" :label-col="{ span: 5 }">
                <a-input placeholder="请输入财务人员" v-model:value="tableForm.storeFinancialStaff" allowClear></a-input>
              </a-form-item>
            </div>
          </td>
        </tr>
      </table>
    </div>
  </a-modal>
</template>

<script>
import setting from '@/config/setting'
import * as formInfoApi from '@/api/ams/formInfo.js'
export default {
  name: 'downloadDoc',
  emits: ['done', 'update:visible'],
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 修改回显的数据
    data: Object
  },
  watch: {
    data() {
      this.form = Object.assign({}, this.data)
      this.getFormInfoPsd()
    }
  },
  data() {
    return {
      form: Object.assign({}, this.data),
      isUpdate: false,
      updateId: null,
      tableForm: {
        approveOperator: '',
        storeOperator: '',
        approveAssetManager: '',
        storeAssetManager: '',
        approveFinancialStaff: '',
        storeFinancialStaff: '',
        leader: '',
        operator: ''
      }
    }
  },
  methods: {
    // 获取表单pdf数据
    getFormInfoPsd() {
      formInfoApi
        .getFormInfoPdf({ formId: this.form.id })
        .then((res) => {
          if (res.code === 0) {
            if (res.data.length > 0) {
              this.isUpdate = true
              if (res.data[0].params) {
                const params = JSON.parse(res.data[0].params)
                this.tableForm = params
                this.tableForm.leader = '黄鹏'
                this.tableForm.operator = '张瑜'
                this.updateId = res.data[0].id
              }
            } else {
              this.isUpdate = false
              this.tableForm = {}
              this.tableForm.leader = '黄鹏'
              this.tableForm.operator = '张瑜'
            }
            // const index = this.form.applicantName.indexOf('（')
            // this.tableForm.operator = this.form.applicantName.substring(
            //   0,
            //   index
            // )
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    },
    // 保存
    save() {
      let params = ''
      let data = {}
      if (this.tableForm) {
        params = JSON.stringify(this.tableForm)
      }
      if (this.isUpdate) {
        data = { id: this.updateId, params }
      } else {
        data = { formId: this.form.id, params }
      }
      formInfoApi
        .formInfoPdf(data, this.isUpdate)
        .then((res) => {
          if (res.code === 0) {
            this.$message.success(res.msg)
            this.updateVisible(false)
            this.$emit('done')
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    },
    savePdf() {
      if (!this.isUpdate) {
        this.$message.error('请先保存PDF数据')
        return
      }
      this.loading = true
      formInfoApi
        .downloadPdf(this.updateId)
        .then((res) => {
          this.loading = false
          console.log('code', this.form)
          if (res.code === 0) {
            const url = setting.downloadUrl + res.data
            const link = document.createElement('a')
            link.style.display = 'none'
            link.href = url
            link.setAttribute(
              'download',
              '厦门边检总站公物仓资产调剂单' + '.pdf'
            )
            document.body.appendChild(link)
            link.click()
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.loading = false
          this.$message.error(e.message)
        })
    },
    // 取消
    handleCancle() {
      this.updateVisible(false)
    },
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value)
    }
  }
}
</script>

<style lang="less" scoped>
.form-title {
  font-size: 24px;
  font-weight: 600;
  text-align: center;
  padding: 20px 0;
}

.table-flex {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.tr_hover:hover {
  background-color: #e6f7ff;
}

.suggest {
  width: 100px;
}

.table-sub {
  display: flex;
  justify-content: space-around;
  align-content: center;
}

.sub-title {
  width: 200px;
}

.table :deep(.ant-form-item) {
  margin-bottom: 0;
}
</style>
